import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'JWLS',
    lpAddresses: {
      97: '',
      56: '0x7E26C6DB3B1B51FEb87cd1eC1F477a130edC8743',
    },
    tokenSymbol: 'SYRUP',
    tokenAddresses: {
      97: '',
      56: '0x7E26C6DB3B1B51FEb87cd1eC1F477a130edC8743',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    lpSymbol: 'JWLS-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xBA729684Bf14D79983654c72b5210497F80cDb30',   // lp address token-bnb
    },
    tokenSymbol: 'JWLS',
    tokenAddresses: {
      97: '',
      56: '0x7E26C6DB3B1B51FEb87cd1eC1F477a130edC8743', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

]

export default farms
