import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'JWLS',
    tokenAddress: '0x7E26C6DB3B1B51FEb87cd1eC1F477a130edC8743',   // token address
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x7E26C6DB3B1B51FEb87cd1eC1F477a130edC8743',  // token address
    contractAddress: {
      97: '',
      56: '0x09ec2908Ab3c66789f49bE4Ed1Bcc55fD1f847BE',  /// masterchef address
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
 
]

export default pools
